<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12  color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
              <SideMenu :indexsec="0"/>
          </div>

          <!--<div class="Content_Radius">-->

          <div id="stn-edituser" class="stn_edituserAdd">
            <div class="Title_AgregarP"><p>AGREGAR PARTIDO</p></div>
            <form
              @submit.prevent="
                submit(
                  opname_form, descrp_form, hr_form, typegame_form, type_form, stadium_form, timezone_form, file1, team_form, tournament_form, profile_form
                )
              "
            >
              <div class="row mx-0 row-one">
                <div class="F1_C1">
                  <label class="labelN color-1" for="inputN">VS</label>
                  <b-form-input
                  
                    v-model="opname_form"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="F1_C2">
                  <label class="labelA color-1" for="inputA"
                    >Fecha</label
                  >
                  <b-form-input
                    v-model="descrp_form"
                    required
                    
                    id="inputA"
                    type="date"
                  />
                </div>
                <div class="F1_C3">
                  <label class="labelE color-1" for="inputE">Hora</label>
                  <b-form-input   v-model="hr_form" required id="inputE" type="time" />
                </div>
              </div>

              <div class="row mx-0 row-two">
                <div class="F2_C1">
                  <label class="labelTel color-1" for="inputTel">Estadio</label>
                  <b-form-input
                  
                    v-model="stadium_form"
                    required
                    id="inputTel"
                    type="text"
                  />
                </div>
               
                <div class="F2_C2">
                  <label class="labelR color-1" for="inputR">Tipo</label>
                  <b-dropdown>
                    <template #button-content>
                      <div class="row"> <span class="drop-text">{{type_form}}</span></div>
                    </template>

                    <b-dropdown-item @click="changeType('Local')">
                      Local
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeType('Visitante')">
                      Visitante
                    </b-dropdown-item>
          
                  </b-dropdown>
                </div>
                 <div class="F2_C3">
                  <label class="labelP color-1" for="inputP"
                    >Horario de</label
                  >
                  <b-form-input
                    v-model="timezone_form"
                    required
                    id="inputP"
                    type="text"
                  />
                </div>
              </div>

                <div class="row mx-0 row-two">
                  <div class="F3_C1">
                  <label class="labelP color-1" for="inputP"
                    >Tipo de partido</label
                  >
                  <b-form-input
                    v-model="typegame_form"
                    required
                    id="inputP"
                    type="text"
                    :placeholder="'Jornada, Cuartos de final...'"
                  />
                </div>
                <div class="F3_C2">
                    <label class="labelEq color-1" >Equipo</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{team_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.teams" :key="key">
                          <b-dropdown-item @click="changeTeam(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   <div class="F3_C3">
                    <label class="labelTr color-1" >Torneo</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{tournament_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.tournaments" :key="key">
                          <b-dropdown-item @click="changeTournament(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>

              </div>

              <div class="row mx-0 row-two">
               
                   <div class="F4_C1">
                    <label class="labelpr color-1" >Perfil</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{profile_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.profiles" :key="key">
                          <b-dropdown-item @click="changeProfile(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   
                   <div class="F4_C2">
                    <label class="labelImg color-1" for="inputImg">Imagen</label>
                        <b-form-file
                          v-model="file1"
                          id="inputImg"
                          
                          type="file"
                          name="inputImg"
                          :placeholder="'Subir imagen'"
                        />
                   </div>
              </div>

              <div class="div-image mtrow">
                  
                </div> 


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El partido se agrego correctamente</p>
                    
                  </div>
                  <div class="">
                    <div class="btns-dflx">
                   <b-button class="btn-modal"  @click="editarOtro()"
                                      >AÑADIR OTRO</b-button
                                    >
                  <b-button class="btn-modal"  @click="toEdit()"
                                      >EDITAR</b-button
                                    >
                  
                  <b-button class="btn-modal" @click="toPage()"
                    >VER PARTIDO</b-button
                  >
                    </div>
                   
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
          </div>

        <!--</div>-->

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import Header from '../../components/Header'

import { mapActions } from "vuex";
export default {
  name: "AddUser",
   components: {
    Header,
    SideMenu
  },
  data() {
    return {
      opname_form: "", 
      descrp_form: "" , 
      hr_form: "" , 
      typegame_form: "" , 
      type_form: "Local" , 
      stadium_form: "Héroe de Nacozari" , 
      timezone_form: "Sonora" ,
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,

      profile_form:"",
      profile_name:"Perfil de color",
      team_form:"",
      team_name:"Equipo",
      tournament_form:"",
      tournament_name:"Torneo",

      added:''
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    }, 
  created() {
    this.getProfiles('perfiles')
    this.getTournaments('torneos')
    this.getTeams('equipos')
    
  },
  computed:{
    teams() {
          return this.$store.getters["teams/data"];
    },
    tournaments() {
      return this.$store.getters["tournament/data"];
    },
    profiles() {
      return this.$store.getters["profile/data"];
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },
  methods: {
     ...mapActions('profile', ['getProfiles']),
     ...mapActions('teams', ['getTeams']),
     ...mapActions('tournament', ['getTournaments']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (opname, descrp, hr, typegame, type, stadium, timezone, image, id_team, id_tournament, id_profile) {
    
     if(id_team == '' || id_team == undefined || id_team==null){
               this.status='error'
              this.msg='Elige un equipo.'
      }else if(id_tournament == '' || id_tournament == undefined || id_tournament==null){
               this.status='error'
              this.msg='Elige un torneo.'
      }else if(id_profile == '' || id_profile == undefined || id_profile==null){
              this.status='error'
              this.msg='Elige un perfil de color.'
      }else if(image != null){

          if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{

          id_tournament = String(id_tournament)
          id_profile =  String(id_profile)
          id_team = String(id_team)
        
          var data = new  FormData();
      
          data.append('image', image);
          data.append('opname', opname);
          data.append('descrp', descrp);
          data.append('hr',hr);
          data.append('typegame', typegame);
          data.append('type', type);
          data.append('stadium', stadium);
          data.append('timezone', timezone);
          data.append('id_tournament', id_tournament);
          data.append('id_profile', id_profile);
          data.append('id_team', id_team);

         
          data.append('_method', 'POST');
       
        
              
          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'calendar', item: data});
        
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
            
              this.added = result.added
              this.showModal() 
          }

            }
         
       
      }else{
          id_tournament = String(id_tournament)
          id_profile =  String(id_profile)
          id_team = String(id_team)
        
        let data ={
      
         'image': null,
         'opname': opname,
         'descrp': descrp,
         'hr':hr,
         'typegame': typegame,
         'type': type,
         'stadium': stadium,
         'timezone': timezone,
         'id_tournament': id_tournament,
         'id_profile': id_profile,
         'id_team': id_team
         
         }
        
              
          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'calendar', item: data});
        
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
            
              this.added = result.added
              this.showModal() 
          }
      }
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },

    toPage: function () {
       this.$router.push("/calendario/"+this.added.id).catch((err) => {});
    },

    toEdit: function () {
       this.$router.push("/editar/partido/"+this.added.id).catch((err) => {});
    },

    changeType: function (value) {
      if(value =='Local'){
        this.stadium_form ='Héroe de Nacozari'
      }else{
        this.stadium_form =''
      }
      this.type_form = value;
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
     changeTournament: function (value, name) {
     
       this.tournament_form = value;
      this.tournament_name = name;

    },
     changeTeam: function (value, name) {
     
       this.team_form = value;
      this.team_name = name;

    },
     changeProfile: function (value, name) {
     
       this.profile_form = value;
      this.profile_name = name;

    },
  },
};
</script>


